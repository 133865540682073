<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
//import router from "@/router/index.js";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Recherche de ligne",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Recherche de ligne",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Recherche de ligne",
          active: true,
        },
      ],
      thenDay: "",
      id: "",
      totalLine: 0,
      totalRowsDisable: 0,
      currentPage: 1,
      perPage: 100,
      pageOptions: [500, 1000, 2000],
      filter: null,
      filterOn: [],
      lineData: [],
      alerteDataDes: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "user", label: "Passager" },
        { key: "telephone", label: "Telephone " },
        { key: `dateHeure`, label: "Date de voyage" },
        { key: "lieuDepart", sortable: true, label: "Départ " },
        { key: "lieuArrivee", sortable: true, label: "Arrivé " },
        { key: `createdAt`, label: "Date de recherche" },
        

        
        
        
        { key: "sieges", label: "Nombre de sièges" },
      ],

      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDatTrajetRechercher: [],
      newStart: false,
      showdisable: false,
      parBon: 500,
      history:{
        id:null,
        user:null,
      },
      deleteModal : false,
    };
  },

  async mounted() {
    this.init();
  },

  methods: {
    async init() {
      const request = await apiRequest(
        "GET",
        "admin/recherches-bus",
        undefined,
        false
      );

      if (request && request.status ==200 ) {
        console.log("les données des lignes recherchées", request.data)
      
        request.data.rechercheFound.map(el => {
          this.lineData.push(el)
        })
        //this.id = recherche.id;

        this.totalLine = this.lineData.length;
        console.log("this.lineData", this.lineData)
      }
    },
    // eslint-disable-next-line no-unused-vars
    

    goToUserDetail(id) {
     //const routeData = router.resolve({name: 'infoUserPage', params:{idUser:id}});
     //router.push(`/user/user_info/${id}`);
     window.open(`/user/user_info/${id}`, '_blank', 'noreferrer');

    // window.open(routeData.href, '_blank');
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="containerDot" v-if="lineData.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">
            <div
              class="row d-flex justify-content-between align-items-center p-3"
            >
              <div>
                <h4 class="card-title mt-4">
                  Total recherches : {{ totalLine }}
                </h4>
              </div>
              <div>
                <b-pagination
      v-model="currentPage"
      :total-rows="totalLine"
      :per-page="perPage"
      :limit="1"
      first-text="◀"
      last-text="▶"
      aria-controls="my-table"
    ></b-pagination>
              </div>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active"
                      >Liste des lignes recherchées</a
                    >
                  </template>
                  <div
                    class="row inputCol d-flex justify-content-between mt-4 mb-2"
                  >
                    <div class="p-3"></div>
                    <!-- Search -->
                    <div class="p-3">
                      <div class="input">
                        <input
                          type="search"
                          id="serchInput"
                          placeholder="Rechercher..."
                          v-model="recherche"
                          @keyup.enter="userSearch"
                        />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="
                        newDatTrajetRechercher.length != 0
                          ? newDatTrajetRechercher
                          : lineData
                      "
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(createdAt)="row">
                        {{ new Date(row.value).toLocaleDateString() }}
                      </template>
                      <template v-slot:cell(dateHeure)="row">
                        {{ new Date(row.value).toLocaleDateString() +" "+ new Date(row.value).toLocaleTimeString('FR-fr') }}
                      </template>
                      <template v-slot:cell(user)="row">
                        {{ row.value.lastname +" " +row.value.firstname}}
                      </template>
                      <template v-slot:cell(telephone)="row">
                        <a :href="'https://wa.me/'+row.item.user.indicatifTel+row.item.user.tel">
                          {{ row.item.user.indicatifTel +" " +row.item.user.tel}}
                        </a>
                        
                      </template>

                      

                      

                     
                    </b-table>
                  </div>
                  <div class="row justify-content-end">
                    <b-pagination
      v-model="currentPage"
      :total-rows="totalLine"
      :per-page="perPage"
      :limit="1"
      first-text="◀"
      last-text="▶"
      aria-controls="my-table"
    ></b-pagination>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showdisable"
      title="Voulez-vous vraiment suspendre cet utilisateur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactive"
          >Oui</b-button
        >
      </div>
    </b-modal>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="deleteModal"
      title="Choisissez le type de suppression !"
      title-class="font-18"
      hide-footer
    >
    <div class="d-flex py-3 justify-content-center text-center ">
          <i class="fas fa-times text-danger fa-xl font-32  p-2 border border-danger rounded-circle"></i>
        </div>
      <div class="d-flex justify-content-between mt-3 ">
        
        <b-button variant="danger" @click="deleteAllHistory">Tout l'historique</b-button>
        <b-button variant="success" class=" " @click="deleteOneHistory"
          >Historique actuel</b-button
        >
        <b-button variant="info" class=" mr-4" @click="deleteModal =false"
          >Annuler</b-button>
      </div>
    </b-modal>
  </Layout>
</template>

<style>
thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  top: -50%;

  flex: 60%;

  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3AAA35;
  border-color: #fff;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -13%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}
</style>
